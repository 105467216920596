import { Grid } from '@mui/material'
import { styled } from '@mui/system'

const KeyGrid = styled(Grid)({
  border: '0.13em solid silver',
  marginLeft: '0.1em',
  marginRight: '0.1em',
  marginBottom: '0.1em',
  minHeight: '2.75em',
  fontWeight: 'bold',
  display: 'flex',
  paddingTop: '0em !important',
  paddingLeft: '0em !important',
  justifyContent: 'center',
  textTransform: 'uppercase',
  '& span': {
    display: 'flex',
    alignItems: 'center'
  }
})

export default KeyGrid
