import { Button } from '@mui/material'
import { styled } from '@mui/system'

const ActionButton = styled(Button)({
  backgroundColor: '#000000',
  color: 'white',
  '&:hover': {
    backgroundColor: '#000025'
  }
})

export default ActionButton
