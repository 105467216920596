import { Container } from '@mui/material'
import { useSelector } from 'react-redux'
import './App.css'
import GameHandler from './Pages/GameHandler'
import Intro from './Pages/Intro'
import { UserInfoReducerType } from './Store/UserInfo/reducer'

const App = () => {
  const hasUnderstoodRules = useSelector<{userInfoReducer: UserInfoReducerType}>(state => state.userInfoReducer.hasUnderstoodRules)

  return (
    <Container maxWidth="sm">
      {hasUnderstoodRules
        ? <GameHandler />
        : <Intro />}
    </Container>
  )
}
export default App
