const getWordFromStore = (words: Array<string>, length:number):string => {
  if (length === -1) {
    return words[Math.floor(Math.random() * words.length)]
  }

  const wordsWithExactLength = words.filter(e =>
    e.length === length
  )

  return wordsWithExactLength[Math.floor(Math.random() * wordsWithExactLength.length)]
}

export default getWordFromStore
