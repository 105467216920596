import { Button } from '@mui/material'
import { styled } from '@mui/system'

const SelectorButton = styled(Button)({
  width: '100%',
  backgroundColor: 'white',
  outlineColor: 'silver',
  border: '1px solid silver',
  color: 'black',
  '&:hover': {
    backgroundColor: '#fffffd',
    outlineColor: 'silver',
    border: '1px solid silver'
  }
})

export default SelectorButton
