const getDifferentLenghts = (words:Array<string>) => {
  if (words.length === 0) {
    return []
  }

  const setOfWordLengths = new Set(words.map(e => e.length))

  return Array.from(setOfWordLengths)
}

export default getDifferentLenghts
