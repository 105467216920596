import { FC } from 'react'
import './Hangman.css'

type Props = {
    width?: string
    wrongGuessLength?: number
}

const Hangman:FC<Props> = ({ width, wrongGuessLength = 10 }) => {
  const elements = [
    <path key="01" d="M1,11 h8" />,
    <path key="02" d="M9,11 v-10" />,
    <path key="03" d="M9,1 h-4" />,
    <path key="04" d="M5,1 v2" />,
    <circle key="05" cx="5" cy="4" r="1" />,
    <path key="06" d="M5,5 v3" />,
    <path key="07" d="M5,5 l-2,2" />,
    <path key="08" d="M5,5 l2,2" />,
    <path key="09" d="M5,8 l-2,2" />,
    <path key="10" d="M5,8 l2,2" />
  ]

  return <svg viewBox="0 0 10 12" width={width}>
    {
      elements.filter((_, index) => index < wrongGuessLength)
        .map((elem, index) => ({ ...elem, key: index }))
    }
  </svg>
}

export default Hangman
