import { Grid } from '@mui/material'
import { styled } from '@mui/system'

const PageGrid = styled(Grid)({
  textAlign: 'center',
  paddingTop: '2em',
  paddingBottom: '2em'
})

export default PageGrid
