import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserInfoReducerType } from '../Store/UserInfo/reducer'
import { WordsReducerType } from '../Store/Words/reducer'
import WordPlayArea from './Components/WordPlayArea'
import CharKeyboard from './Components/CharKeyboard'
import Hangman from './Components/Hangman'
import getWrongGuessCount from './utils/getWrongGuessCount'
import { Grid } from '@mui/material'
import ActionButton from '../Components/ActionButton'
import userInfoReducerTypes from '../Store/UserInfo/types'
import wordReducerTypes from '../Store/Words/types'

const PlayScreen = () => {
  const dispatch = useDispatch()
  const hasUserLost = useSelector<{userInfoReducer: UserInfoReducerType}>(state => state.userInfoReducer.hasUserLost)
  const hasUserWon = useSelector<{userInfoReducer: UserInfoReducerType}>(state => state.userInfoReducer.hasUserWon)
  const lastSelectedLength = useSelector<{userInfoReducer: UserInfoReducerType}>(state => state.userInfoReducer.lastSelectedLength)
  const chars = useSelector<{wordsReducer: WordsReducerType}>(state => state.wordsReducer.chars) as Array<string>
  const word = useSelector<{wordsReducer: WordsReducerType}>(state => state.wordsReducer.currentWord) as string
  const pressedKeys = new Set<string>(useSelector<{wordsReducer: WordsReducerType}>(state => state.wordsReducer.pressedKeys) as Array<string>)
  const [wrongGuessLength, setWrongGuessLength] = useState<number>(-1)
  const hasGameEnded = hasUserLost || hasUserWon

  const onKeyDown = (e:any) => {
    if (chars.find(c => c === e.key) && !hasGameEnded) {
      dispatch({
        type: wordReducerTypes.SET_PRESSED_KEYS,
        payload: { key: e.key }
      })
    }
  }

  useEffect(() => {
    if (!word) {
      dispatch({
        type: wordReducerTypes.SET_RANDOM_WORD,
        payload: { lastSelectedLength }
      })
    } else {
      setWrongGuessLength(getWrongGuessCount(Array.from(pressedKeys), word))
    }
  }, [word, lastSelectedLength, pressedKeys])

  useEffect(() => {
    if (wrongGuessLength === 10) {
      dispatch({
        type: userInfoReducerTypes.USER_LOST
      })
    }
  }, [wrongGuessLength])

  useEffect(() => {
    if (word && word.split('').every(char => pressedKeys.has(char))) {
      dispatch({
        type: userInfoReducerTypes.USER_WON
      })
    }
  }, [pressedKeys])

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [hasGameEnded])

  const resetGame = () => {
    dispatch(({
      type: userInfoReducerTypes.RESET_GAME
    }))

    dispatch({
      type: wordReducerTypes.RESET_WORD
    })
  }

  return <Grid container spacing={2}>
    <Grid item xl={12} md={12} xs={12}>
      <Hangman width="100"
               wrongGuessLength={wrongGuessLength} />
    </Grid>

    <Grid item xl={12} md={12} xs={12}>
      {word &&
        <WordPlayArea lastSelectedLength={Number(lastSelectedLength)}
                      word={word}
                      pressedKeys={pressedKeys} />
      }
    </Grid>

    <Grid item xl={12} md={12} xs={12}>
      <CharKeyboard chars={chars}
                    pressedKeys={pressedKeys}
                    onKeyDown={onKeyDown} />
    </Grid>

    <Grid item xl={12} md={12} xs={12}>
      <Grid container spacing={2}>
        <Grid item xl={6} md={6} xs={6}>
          <ActionButton variant="outlined"
                        style={{ backgroundColor: 'white', color: 'black', border: '1px solid silver' }}
                        onClick={() => !hasUserWon && dispatch({ type: userInfoReducerTypes.USER_LOST })}>
            End game
          </ActionButton>
        </Grid>

        <Grid item xl={6} md={6} xs={6}>
          <ActionButton onClick={resetGame}>Start new game</ActionButton>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
}

export default PlayScreen
