import { ActionType } from '../types'
import types from './types'

const initialState = {
  hasUnderstoodRules: false,
  hasGameStarted: false,
  lastSelectedLength: -1,
  hasUserLost: false,
  hasUserWon: false
}

export default function reducer (state = initialState, action: ActionType) {
  switch (action.type) {
    case types.USER_UNDERSTOOD_RULES:
      return {
        ...state,
        hasUnderstoodRules: true
      }
    case types.USER_SET_SELECTED_LENGTH:
      return {
        ...state,
        lastSelectedLength: action.payload?.lastSelectedLength ?? -1
      }
    case types.USER_SET_GAME_STARTED:
      return {
        ...state,
        hasGameStarted: action.payload?.hasGameStarted ?? false
      }
    case types.USER_LOST:
      return {
        ...state,
        hasUserLost: true
      }
    case types.USER_WON:
      return {
        ...state,
        hasUserWon: true
      }
    case types.RESET_GAME:
      return {
        ...initialState,
        hasUnderstoodRules: true
      }
    default:
      return state
  }
}

export type UserInfoReducerType = typeof initialState
