import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import PageGrid from '../Components/PageGrid'
import { UserInfoReducerType } from '../Store/UserInfo/reducer'
import GameTitle from './Components/GameTitle'
import PlayScreen from './PlayScreen'
import WordSelector from './WordSelector'

const GameHandler = () => {
  const hasGameStarted = useSelector<{userInfoReducer: UserInfoReducerType}>(state => state.userInfoReducer.hasGameStarted)

  return <PageGrid container spacing={2}>
    <Grid item xl={12} md={12} xs={12}>
      <GameTitle />
    </Grid>

    <Grid item xl={12} md={12} xs={12}>
      {hasGameStarted
        ? <PlayScreen />
        : <WordSelector />
      }
    </Grid>
  </PageGrid>
}

export default GameHandler
