import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import CharGrid from '../../Components/CharGrid'
import KeyboardGrid from '../../Components/KeyBoardGrid'
import GameStatus from './GameStatus'

type Props = {
    lastSelectedLength:number
    word: string
    pressedKeys: any
}

const WordPlayArea:FC<Props> = ({ lastSelectedLength, word, pressedKeys }) => {
  return <Grid container spacing={2}>
    <Grid item xl={12} md={12} xs={12}>
      <GameStatus />
    </Grid>

    <Grid item xl={12} md={12} xs={12}>
      <Typography variant="body1" gutterBottom style={{ display: 'flex', justifyContent: 'left' }}>
        It&apos;s a {lastSelectedLength} letter word
      </Typography>
    </Grid>

    <Grid item xl={12} md={12} xs={12}>
      <KeyboardGrid container spacing={2}>
        {word && word.split('').map((char, index) =>
          <CharGrid item xl={1} md={1} xs={1} key={index}>
            {pressedKeys.has(char) && <span>{char}</span>}
          </CharGrid>
        )}
      </KeyboardGrid>
    </Grid>
  </Grid>
}

export default WordPlayArea
