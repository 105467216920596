import { Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ActionButton from '../Components/ActionButton'
import SelectorButton from '../Components/SelectorButton'

import types from '../Store/UserInfo/types'
import { WordsReducerType } from '../Store/Words/reducer'
import getDifferentLenghts from './utils/getDifferentLengths'

const WordSelector = () => {
  const dispatch = useDispatch()
  const words = useSelector<{wordsReducer: WordsReducerType}>(state => state.wordsReducer.words)
  const [possibleWordLenghts] = useState<Array<number>>(getDifferentLenghts(words as Array<string>))
  const [selectedIndex, setSelectedIndex] = useState<string>('random')
  const randomLength = `${possibleWordLenghts[Math.floor(Math.random() * possibleWordLenghts.length)]}`

  useEffect(() => {
    dispatch({
      type: types.USER_SET_SELECTED_LENGTH,
      payload: { lastSelectedLength: randomLength }
    })
  }, [])

  const onLengthSelect = (index:string, element?:any) => {
    dispatch({
      type: types.USER_SET_SELECTED_LENGTH,
      payload: { lastSelectedLength: index === 'random' ? randomLength : element.target.innerText }
    })

    setSelectedIndex(index)
  }

  const onStart = () => {
    dispatch({
      type: types.USER_SET_GAME_STARTED,
      payload: { hasGameStarted: true }
    })
  }

  return <Grid container spacing={2}>
    <Grid item xl={12} md={12} xs={12}>
      <Typography variant="h5" component="div">
        Let&apos;s play <b>Hangman!</b>
      </Typography>

      <Typography variant="h6" component="div">
        How many letters do you want in your word?
      </Typography>
    </Grid>

    <Grid item xl={12} md={12} xs={12}>
      <Grid container spacing={2}>
        <Grid item xl={12} md={12} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid container spacing={2} style={{ width: '70%' }}>
            {possibleWordLenghts.sort((a, b) => a - b).map((wordLength, index) =>
              <Grid item xl={4} md={4} xs={4} key={index}>
                <SelectorButton variant="outlined"
                                style={selectedIndex === `${index}`
                                  ? {
                                      backgroundColor: 'black',
                                      color: 'white'
                                    }
                                  : {}}
                                onClick={e => onLengthSelect(`${index}`, e)}>
                  {wordLength}
                </SelectorButton>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xl={12} md={12} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid container spacing={2} style={{ width: '70%' }}>
            <Grid item xl={12} md={12} xs={12}>
              <SelectorButton variant="outlined"
                              style={selectedIndex === 'random'
                                ? {
                                    backgroundColor: 'black',
                                    color: 'white'
                                  }
                                : {}}
                              onClick={() => onLengthSelect('random')}>
                random
              </SelectorButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <Grid item xl={12} md={12} xs={12}>
      <ActionButton variant="contained"
                    style={{ width: '35%' }}
                    onClick={onStart}>Let&apos;s play!</ActionButton>
    </Grid>
  </Grid>
}

export default WordSelector
