import useWindowSize from 'react-use/lib/useWindowSize'
import MoodIcon from '@mui/icons-material/Mood'
import MoodBadIcon from '@mui/icons-material/MoodBad'
import { Grid, Typography } from '@mui/material'
import Confetti from 'react-confetti'
import { useSelector } from 'react-redux'
import { UserInfoReducerType } from '../../Store/UserInfo/reducer'

const GameStatus = () => {
  const { width, height } = useWindowSize()

  const hasUserLost = useSelector<{userInfoReducer: UserInfoReducerType}>(state => state.userInfoReducer.hasUserLost)
  const hasUserWon = useSelector<{userInfoReducer: UserInfoReducerType}>(state => state.userInfoReducer.hasUserWon)

  return <Grid container spacing={2}>
    <Grid item xl={12} md={12} xs={12} style={{ display: 'flex', justifyContent: 'left' }}>
      {hasUserLost &&
        <Typography variant="h5" component="div" style={{ color: 'red' }}>
          <MoodBadIcon /> You&apos;ve lost
        </Typography>
      }

      {hasUserWon &&
        <>
          <Confetti width={width} height={height} />
          <Typography variant="h5" component="div" style={{ color: 'green' }}>
              <MoodIcon color="success" /> You&apos;ve won
          </Typography>
        </>
      }
    </Grid>
  </Grid>
}

export default GameStatus
