import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import KeyboardGrid from '../../Components/KeyBoardGrid'
import KeyGrid from '../../Components/KeyGrid'
import './CharKeyboard.css'

type Props = {
    chars: Array<string>,
    pressedKeys: any,
    onKeyDown: (e:any) => void
}

const CharKeyboard:FC<Props> = ({ chars, pressedKeys, onKeyDown }) => {
  return <Grid container spacing={2}>
    <Grid item xl={12} md={12} xs={12}>
      <Typography variant="body1" gutterBottom style={{ display: 'flex', justifyContent: 'left' }}>
        Play with a word
      </Typography>
    </Grid>

    <Grid item xl={12} md={12} xs={12}>
      <KeyboardGrid container spacing={2}>
        {chars.map((char, index) =>
          <KeyGrid item xl={1} md={1} xs={1} key={index}
                        onClick={() => onKeyDown({ key: char })}
                        style={{ color: pressedKeys.has(char) ? 'silver' : 'black' }}>
            <span>{char}</span>
          </KeyGrid>
        )}
       </KeyboardGrid>
    </Grid>
  </Grid>
}

export default CharKeyboard
