import { useDispatch } from 'react-redux'
import Hangman from './Components/Hangman'
import types from '../Store/UserInfo/types'
import { Grid, Typography } from '@mui/material'
import ActionButton from '../Components/ActionButton'
import PageGrid from '../Components/PageGrid'
import GameTitle from './Components/GameTitle'

const Intro = () => {
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch({ type: types.USER_UNDERSTOOD_RULES })
  }

  return <PageGrid container spacing={2}>
    <Grid item xl={12} md={12} xs={12}>
      <GameTitle />
    </Grid>

    <Grid item xl={12} md={12} xs={12}>
      <Hangman width="250" />
    </Grid>

    <Grid item xl={12} md={12} xs={12}>
      <Typography variant="h4" component="div">
        Game instructions
      </Typography>
    </Grid>

    <Grid item xl={12} md={12} xs={12}>
      <Typography variant="body1" gutterBottom>
        Welcome to {`${new Date().getFullYear()}`}&apos;s best Hangman game! <span>🎮</span>
      </Typography>
      <Typography variant="body1" gutterBottom>
        First you may select the length of the word then simply use your keyboard <span>⌨️</span>. Try also clicking on the visual chars to guess the secret <span>🤫</span>.
      </Typography>
    </Grid>

    <Grid item xl={12} md={12} xs={12}>
      <ActionButton variant="contained"
                    onClick={onClick}>Got it!</ActionButton>
    </Grid>
  </PageGrid>
}

export default Intro
