const types = {
  USER_UNDERSTOOD_RULES: 'user-info/user-understood-rules',
  USER_SET_SELECTED_LENGTH: 'user-info/set-selected-length',
  USER_SET_GAME_STARTED: 'user-info/set-game-started',
  USER_LOST: 'user-info/user-lost',
  USER_WON: 'user-info/user-won',
  RESET_GAME: 'user-info/reset-game'
}

export default types
